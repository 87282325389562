<mat-progress-bar *ngIf="showProgress == true" mode="indeterminate"></mat-progress-bar>

<app-toolbar url="content"></app-toolbar>

<div class="logo-align">
    <a *ngIf="choosenBrand.brandID != 9996 && choosenBrand.brandID != 9995" class="logo-element"
        [href]="choosenBrand.portal_url" target="_blank" rel="noreferrer"><img class="logo" alt="Logo"
            [src]="toolbar_img" /></a>
    <a *ngIf="choosenBrand.brandID == 9996 && this.param.lang == 'de'" class="logo-element"
        [href]="choosenBrand.portal_url" target="_blank" rel="noreferrer"><img style="max-height: 140px;" alt="Logo"
            src="./../../assets/img/vogel-fachbuch.png" /></a>
    <a *ngIf="choosenBrand.brandID == 9995 && this.param.lang == 'de'" class="logo-element"
        [href]="choosenBrand.portal_url" target="_blank" rel="noreferrer"><img style="max-height: 140px;" alt="Logo"
            src="./../../assets/img/win-verlag.png" /></a>
</div>
<!-- content -->
<div *ngIf="ticketDataValidation == false" class="content" role="main">

    <div class="mobile-content">
        <h1 [ngStyle]="{'color': isWIN ? '#51C760' : isIWW ? '#00305e' : '#2289f7' }">
            {{ 'SITE.MAINTEXT' | translate }}</h1>
        <h3>{{ 'SITE.MAINTEXT_2' | translate }}</h3>
        <h3>{{ 'SITE.MAINTEXT_3' | translate }}</h3>
    </div>

    <!-- form -->
    <form [formGroup]="ticketDataForm" (ngSubmit)="submitTicketData($event)" class="form">

        <p *ngIf="isIWW" style="padding: 24px 0 0 0">Sie haben Fragen zur Registrierung / zum Benutzerkonto / zur Lizenzvergabe / Passwort vergessen? Nutzen Sie <a style="color:#00305e" target="_blank" href="https://www.iww.de/hilfe">hier</a> unsere FAQ</p>
        <!-- requestType select -->
        <mat-form-field [ngStyle]="{'display': predefinedRequestType ? 'none' : '' }" appearance="fill" class="start-select">
            <mat-label>{{ 'FORM.REQUESTTYPE' | translate }}</mat-label>
            <mat-select [(value)]="param.requestType" (valueChange)="requestTypeChanged(requestTypeSelect.value)" formControlName="requestTypeControl" [ngModel]="param.requestType"
                #requestTypeSelect>
                <mat-option class="mat-select-pinny" *ngFor="let detail of isIWW ? detailsIWW : details"
                    [value]="detail.value">
                    {{ 'REQUESTTYPES.' + detail.viewValue | translate }}
                </mat-option>
            </mat-select>
            <mat-hint [ngStyle]="{'color': isWIN ? '#51C760' : isIWW ? '#00305e' : '#2289f7' }" class="mobile-magic"
                *ngIf="param.requestType !== 'zua'" align="start">{{ 'FORM.CHOOSEREQUESTTYPE'
                | translate }}</mat-hint>
        </mat-form-field>

        <!-- ZUA TOPIC select -->
        <mat-form-field [ngStyle]="{'margin-top': predefinedRequestType ? '20px' : '' }" *ngIf="param.requestType === 'zua' && choosenBrand.hasPrint == 1 && choosenBrand.datam == 0"
            appearance="fill">
            <mat-label>{{ 'FORM.TOPIC' | translate }}</mat-label>
            <mat-select [(value)]="param.topic" formControlName="topicZUATypeControl" [ngModel]="param.topic"
                #topicZUASelect>
                <mat-option class="mat-select-pinny" *ngFor="let topic of topicZUA" [value]="topic.value">
                    {{ 'TOPIC.' + topic.viewValue | translate }}
                </mat-option>
            </mat-select>
            <mat-hint [ngStyle]="{'color': isWIN ? '#51C760' : isIWW ? '#00305e' : '#2289f7' }" class="mobile-magic"
                align="start">{{ 'FORM.TOPICHINT' | translate }}</mat-hint>
        </mat-form-field>

        <!-- IWW TOPIC select -->
        <mat-form-field
            *ngIf="requestTypeSelect.value == 'ap' || requestTypeSelect.value == 'webiww' || requestTypeSelect.value == 'd'"
            appearance="fill">
            <mat-label>{{ 'FORM.TOPIC' | translate }}</mat-label>
            <mat-select [(value)]="param.topic" formControlName="topicIWWTypeControl" [ngModel]="param.topic" #topicSelect>
                <mat-option class="mat-select-pinny"
                    *ngFor="let topic of requestTypeSelect.value == 'ap' ? topicA : requestTypeSelect.value == 'd' ? topicD : requestTypeSelect.value == 'webiww' ? topicW : ''"
                    [value]="topic.value">
                    {{ 'TOPIC.' + topic.viewValue | translate }}
                </mat-option>
            </mat-select>
            <mat-hint [ngStyle]="{'color': isWIN ? '#51C760' : isIWW ? '#00305e' : '#2289f7' }" class="mobile-magic"
                align="start">{{ 'FORM.TOPICHINT' | translate }}</mat-hint>
        </mat-form-field>

        <!-- text and button - added to dom if requesttype = zua -->
        <div
            *ngIf="(param.requestType === 'zua' && choosenBrand.datam == 1 && choosenBrand.hasPrint == 1) || (param.requestType === 'zua' && param.brand == undefined && formSelectedBrand == undefined)">
            <div class="refer-text">
                <p>{{ 'SITE.DATAMTEXT' | translate }}</p>
            </div>
            <div class="button-2-position">
                <a class="button-1" rel="noreferrer" target="_blank" [href]="choosenBrand.leserservice_link">{{
                    'SITE.BUTTON_NEXT_STEP' | translate }}</a>
            </div>
        </div>

        <!-- brand input field -->
        <mat-form-field appearance="fill"
            *ngIf="!param.brand && ((param.requestType === 'zua' && choosenBrand.hasPrint == 1 && choosenBrand.datam == 0) || (param.requestType === 'run' || param.requestType === 'ts' || param.requestType === 'sa' || param.requestType === 'madr' || param.requestType === 'madst' || param.requestType === 'mae'))">
            <mat-label>{{ 'FORM.BRAND' | translate }}</mat-label>
            <mat-select formControlName="brandFormControl" #brandSelect (ngModelChange)="evaluateBrand($event)">
                <mat-option *ngFor="let brand of brands" [value]="brand.value">
                    {{ brand.viewValue }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- Abonummer input field -->
        <mat-form-field appearance="fill"
            *ngIf="(param.requestType === 'zua' && choosenBrand.hasPrint == 1) || param.topic === 'fa' || param.topic === 'ka' || param.topic === 'n' || param.topic === 'a' || param.topic === 'fb' || param.topic === 'wp' || param.topic === 'ww' || (param.requestType === 'webiww' && param.topic) || param.requestType === 'sf' || param.requestType === 'eshop' || param.requestType === 'app' || param.requestType === 'tp'">
            <mat-label>{{ 'FORM.SUBSCRIPTIONNUMBER' | translate }}</mat-label>
            <input maxlength="64" matInput formControlName="abonummerFormControl">
            <mat-icon matSuffix (click)="openDialog()">info</mat-icon>
            <mat-hint [ngStyle]="{'color': isWIN ? '#51C760' : isIWW ? '#00305e' : '#2289f7' }" class="mobile-magic"
                align="start">{{ 'FORM.SUBSCRIPTIONNUMBERHINT' | translate }}</mat-hint>
        </mat-form-field>

        <!-- Firmenname input field -->
        <mat-form-field appearance="fill"
            *ngIf="(param.requestType === 'zua' && choosenBrand.hasPrint == 1) || param.topic === 'fa' || param.topic === 'ka' || param.topic === 'n' || param.topic === 'a' || param.topic === 'frmg' || param.topic === 'fb' || param.topic === 'wp' || param.topic === 'ft' || param.topic === 'ausk'   || param.topic === 'bl' || param.requestType === 'eus' || param.requestType === 'wlk' || param.topic === 'a' || param.topic === 'ww' || (param.requestType === 'webiww' && param.topic) || param.requestType === 'sf' || param.requestType === 'eshop' || param.requestType === 'app' || param.requestType === 'tp'">
            <mat-label>{{ 'FORM.COMPANY' | translate }}</mat-label>
            <input maxlength="64" matInput formControlName="firmaFormControl">
            <mat-hint [ngStyle]="{'color': isWIN ? '#51C760' : isIWW ? '#00305e' : '#2289f7' }" class="mobile-magic"
                align="start">{{ 'FORM.COMPANYHINT' | translate }}</mat-hint>
        </mat-form-field>

        <!-- Empfänger input field -->
        <mat-form-field appearance="fill" *ngIf="(param.requestType === 'zua' && choosenBrand.hasPrint == 1)">
            <mat-label>{{ 'FORM.RECIPIENT' | translate }}</mat-label>
            <input maxlength="64" matInput formControlName="empfaengerFormControl">
            <mat-hint [ngStyle]="{'color': isWIN ? '#51C760' : isIWW ? '#00305e' : '#2289f7' }" class="mobile-magic"
                align="start">{{ 'FORM.RECIPIENTHINT' | translate }}</mat-hint>
        </mat-form-field>

        <!-- Adresse input field -->
        <mat-form-field appearance="fill"
            *ngIf="param.topic === 'fa' || param.topic === 'ka' || param.topic === 'n' || (param.topic === 'a' && isIWW) || param.topic === 'frmg' || param.topic === 'fb' || param.topic === 'wp' || param.topic === 'ft' || param.requestType === 'eus' || param.requestType === 'wlk' || param.topic === 'ww' || param.topic === 'ausk'   || param.topic === 'bl' || (param.requestType === 'webiww' && param.topic) || param.requestType === 'sf' || param.requestType === 'eshop' || param.requestType === 'app' || param.requestType === 'tp'">
            <mat-label>{{ 'FORM.ADDRESS' | translate }}</mat-label>
            <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" required matInput maxlength="32765"
                formControlName="addressFormControl"></textarea>
            <mat-hint [ngStyle]="{'color': isWIN ? '#51C760' : isIWW ? '#00305e' : '#2289f7' }" class="mobile-magic"
                align="start">{{ 'FORM.ADDRESSHINT' | translate }}</mat-hint>
            <mat-error *ngIf="ticketDataForm.controls.addressFormControl.errors?.required">
                {{ 'FORM.REQUIREDADDRESS' | translate }}
            </mat-error>
        </mat-form-field>

        <!-- straße input field -->
        <mat-form-field *ngIf="(param.requestType === 'zua' && choosenBrand.hasPrint == 1)" appearance="fill">
            <mat-label>{{ 'FORM.STREET' | translate }}</mat-label>
            <input maxlength="64" required matInput formControlName="strasseFormControl">
            <mat-hint align="start">{{ 'FORM.STREETHINT' | translate }}</mat-hint>
            <mat-error *ngIf="ticketDataForm.controls.strasseFormControl.errors?.required">
                {{ 'FORM.STREETHINT' | translate }}
            </mat-error>
        </mat-form-field>

        <!-- plz input field -->
        <mat-form-field *ngIf="(param.requestType === 'zua' && choosenBrand.hasPrint == 1)" appearance="fill">
            <mat-label>{{ 'FORM.PLZ' | translate }}</mat-label>
            <input maxlength="64" required matInput formControlName="plzFormControl">
            <mat-hint align="start">{{ 'FORM.PLZHINT' | translate }}</mat-hint>
            <mat-error *ngIf="ticketDataForm.controls.plzFormControl.errors?.required">
                {{ 'FORM.PLZHINT' | translate }}
            </mat-error>
        </mat-form-field>

        <!-- stadt input field -->
        <mat-form-field *ngIf="(param.requestType === 'zua' && choosenBrand.hasPrint == 1)" appearance="fill">
            <mat-label>{{ 'FORM.CITY' | translate }}</mat-label>
            <input maxlength="64" required matInput formControlName="stadtFormControl">
            <mat-hint align="start">{{ 'FORM.CITYHINT' | translate }}</mat-hint>
            <mat-error *ngIf="ticketDataForm.controls.stadtFormControl.errors?.required">
                {{ 'FORM.CITYHINT' | translate }}
            </mat-error>
        </mat-form-field>

        <!-- land input field -->
        <mat-form-field *ngIf="(param.requestType === 'zua' && choosenBrand.hasPrint == 1)" appearance="fill">
            <mat-label>{{ 'FORM.COUNTRY' | translate }}</mat-label>
            <input maxlength="64" required matInput formControlName="landFormControl">
            <mat-hint align="start">{{ 'FORM.COUNTRYHINT' | translate }}</mat-hint>
            <mat-error *ngIf="ticketDataForm.controls.landFormControl.errors?.required">
                {{ 'FORM.COUNTRYHINT' | translate }}
            </mat-error>
        </mat-form-field>

        <!-- event input field -->
        <mat-form-field appearance="fill" *ngIf="param.requestType === 'mae'">
            <mat-label>{{ 'FORM.EVENTNAME' | translate }}</mat-label>
            <input maxlength="64" matInput formControlName="eventFormControl">
            <mat-hint [ngStyle]="{'color': isWIN ? '#51C760' : isIWW ? '#00305e' : '#2289f7' }" align="start">{{
                'FORM.EVENTHINT' | translate }}</mat-hint>
        </mat-form-field>

        <!-- fullname input field -->
        <mat-form-field [ngStyle]="{'margin-top': predefinedRequestType ? '20px' : '' }" appearance="fill"
            *ngIf="(param.requestType === 'run' || param.requestType === 'ts' || param.requestType === 'sa' || param.requestType === 'madr' || param.requestType === 'madst' || param.requestType === 'mae' || param.topic === 'fa' || param.topic === 'ka' || param.topic === 'n' || (param.topic === 'a' && isIWW) || param.topic === 'frmg' || param.topic === 'fb' || param.topic === 'wp' || param.topic === 'ft' || param.topic === 'ww' || param.topic === 'ausk' || param.requestType === 'eus'  || param.topic === 'bl' || (param.requestType === 'webiww' && param.topic) || param.requestType === 'sf' || param.requestType === 'eshop' || param.requestType === 'app' || param.requestType === 'tp')">
            <mat-label>{{ 'FORM.FULLNAME' | translate }}</mat-label>
            <input maxlength="64" matInput formControlName="fullNameFormControl">
            <mat-hint [ngStyle]="{'color': isWIN ? '#51C760' : isIWW ? '#00305e' : '#2289f7' }" align="start">{{
                'FORM.EXAMPLE' | translate }}: Max Muster</mat-hint>
            <!-- <mat-error *ngIf="ticketDataForm.controls.fullNameFormControl.errors?.required">
                {{ 'FORM.REQUIREDFULLNAME' | translate }}
            </mat-error> -->
        </mat-form-field>

        <!-- telephone input field -->
        <mat-form-field appearance="fill"
            *ngIf="(param.requestType === 'run' || param.requestType === 'ts' || param.requestType === 'sa' || param.requestType === 'madr' || param.requestType === 'madst' || param.requestType === 'mae') || (param.requestType === 'ap' && param.topic) || (param.requestType === 'd' && param.topic) || param.requestType === 'eus' || (param.requestType === 'webiww' && param.topic) || param.requestType === 'wlk' || param.requestType === 'sf' || (param.topic === 'a' && isIWW) || param.topic === 'ww' || param.topic === 'ausk'   || param.topic === 'bl' || param.requestType === 'sf' || param.requestType === 'eshop' || param.requestType === 'app' || param.requestType === 'tp'">
            <mat-label>{{ 'FORM.TELEPHONE' | translate }}</mat-label>
            <input maxlength="32" matInput formControlName="telephoneFormControl">
            <mat-hint [ngStyle]="{'color': isWIN ? '#51C760' : isIWW ? '#00305e' : '#2289f7' }" align="start">{{
                'FORM.EXAMPLE' | translate }}: +49 931 418-0</mat-hint>
        </mat-form-field>

                <!-- Informationsdienst input field -->
                <mat-form-field appearance="fill"
                *ngIf="param.requestType === 'eus'">
                <mat-label>Informationsdienst</mat-label>
                <input maxlength="64" matInput formControlName="informationsFormControl">
                <mat-hint style="color:#00305e" align="start">
                    Bitte geben Sie an, um welchen Informationsdienst es sich handelt.</mat-hint>
            </mat-form-field>

        <!-- Alte Anschrift input field -->
        <mat-form-field appearance="fill" *ngIf="param.topic === 'a' && isIWW">
            <mat-label>Alte Anschrift</mat-label>
            <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" required matInput maxlength="32765"
                formControlName="oldAddressFormControl"></textarea>
                <mat-error *ngIf="ticketDataForm.controls.oldAddressFormControl.errors?.required">
                    {{ 'FORM.REQUIREDADDRESS' | translate }}
                </mat-error>
        </mat-form-field>

        <!-- Neue Anschrift input field -->
        <mat-form-field appearance="fill" *ngIf="param.topic === 'a' && isIWW">
            <mat-label>Neue Anschrift</mat-label>
            <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" required matInput maxlength="32765"
                formControlName="newAddressFormControl"></textarea>
                <mat-error *ngIf="ticketDataForm.controls.newAddressFormControl.errors?.required">
                    {{ 'FORM.REQUIREDADDRESS' | translate }}
                </mat-error>
        </mat-form-field>

        <!-- Rechnungs-, Mahnungs- oder Inkassonummer input field -->
        <mat-form-field appearance="fill" *ngIf="param.topic === 'frmg'">
            <mat-label>Rechnungs-, Mahnungs- oder Inkassonummer</mat-label>
            <input maxlength="64" matInput required formControlName="rechnungMahnungInkassoFormControl">
            <mat-error *ngIf="ticketDataForm.controls.addressFormControl.errors?.required">
                Rechnungs-, Mahnungs- oder Inkassonummer wird benötigt!
            </mat-error>
        </mat-form-field>

        <!-- Ausgabennummer input field -->
        <mat-form-field appearance="fill" *ngIf="param.topic === 'n'">
            <mat-label>Ausgabennummer</mat-label>
            <input maxlength="64" matInput required formControlName="issueFormControl">
        </mat-form-field>

        <!-- email input field -->
        <mat-form-field appearance="fill"
            *ngIf="(param.requestType === 'zua' && choosenBrand.hasPrint == 1 && choosenBrand.datam == 0) || (param.requestType === 'run' || param.requestType === 'ts' || param.requestType === 'sa' || param.requestType === 'madr' || param.requestType === 'madst' || param.requestType === 'mae' || param.topic === 'fa' || param.topic === 'ka' || param.topic === 'n' || param.topic === 'a' || param.topic === 'frmg' || param.topic === 'fb' || param.topic === 'wp' || param.topic === 'ft' || param.topic === 'ww' || param.topic === 'ausk'   || param.topic === 'bl' || (param.requestType === 'webiww' && param.topic) || param.requestType === 'sf' || param.requestType === 'eshop' || param.requestType === 'app' || param.requestType === 'tp')">
            <mat-label>{{ 'FORM.EMAIL' | translate }}</mat-label>
            <input maxlength="64" required matInput formControlName="emailFormControl">
            <mat-hint [ngStyle]="{'color': isWIN ? '#51C760' : isIWW ? '#00305e' : '#2289f7' }" align="start">{{
                'FORM.EXAMPLE' | translate }}: max.mustermann&#64;example.com</mat-hint>
            <mat-error *ngIf="ticketDataForm.controls.emailFormControl.errors?.email">
                {{ 'FORM.REQUIREDVALIDEMAIL' | translate }}
            </mat-error>
            <mat-error *ngIf="ticketDataForm.controls.emailFormControl.errors?.required">
                {{ 'FORM.REQUIREDEMAIL' | translate }}
            </mat-error>
        </mat-form-field>

        <!-- url input field-->
        <mat-form-field appearance="fill" *ngIf="param.requestType === 'webiww' && param.topic">
            <mat-label>URL</mat-label>
            <input type="url" matInput formControlName="urlFormControl">
        </mat-form-field>

        <!-- details input field-->
        <mat-form-field appearance="fill"
            *ngIf="(param.requestType === 'zua' && choosenBrand.hasPrint == 1 && choosenBrand.datam == 0) || param.requestType === 'run' || param.requestType === 'ts' || param.requestType === 'sa' || param.requestType === 'madr' || param.requestType === 'madst' || param.requestType === 'mae' || param.topic === 'fa' || param.topic === 'ka' || param.topic === 'n' || param.topic === 'a' || param.topic === 'frmg' || param.topic === 'fb' || param.topic === 'wp' || param.topic === 'ft' || (param.requestType === 'webiww' && param.topic) || (param.requestType === 'd' && param.topic) || param.requestType === 'sf' || param.requestType === 'eshop' || param.requestType === 'app' || param.requestType === 'tp' || param.requestType === 'eus' || param.requestType === 'wlk'">
            <mat-label>{{ 'FORM.DETAILS' | translate }}</mat-label>
            <textarea [(ngModel)]="param.info" cdkTextareaAutosize cdkAutosizeMinRows="6" required matInput maxlength="32765"
                formControlName="detailsFormControl"></textarea>
            <mat-hint [ngStyle]="{'color': isWIN ? '#51C760' : isIWW ? '#00305e' : '#2289f7' }" align="start">{{
                'FORM.DETAILSINFO' | translate }}</mat-hint>
            <mat-error *ngIf="ticketDataForm.controls.detailsFormControl.errors?.required">
                {{ 'FORM.REQUIREDDETAILS' | translate }}
            </mat-error>
        </mat-form-field>

        <div
            *ngIf="(param.requestType === 'zua' && choosenBrand.hasPrint == 1 && choosenBrand.datam == 0) || (param.requestType === 'run' || param.requestType === 'ts' || param.requestType === 'sa' || param.requestType === 'madr' || param.requestType === 'madst' || param.requestType === 'mae' || param.requestType === 'aa' || (param.requestType === 'ap' && param.topic) || (param.requestType === 'd' && param.topic) || param.requestType === 'eus' || (param.requestType === 'webiww' && param.topic) || param.requestType === 'wlk' || param.requestType === 'sf' || param.requestType === 'eshop' || param.requestType === 'app' || param.requestType === 'tp')">
            <!-- data privacy policy text -->
            <p [ngStyle]="{'padding':param.brand == undefined ? '' : '8px 0' }">
                {{ 'FORM.DATAPROTECTION_1' | translate }} <a
                    [ngStyle]="{'color': isWIN ? '#51C760' : isIWW ? '#00305e' : '#2289f7' }" [attr.href]="isWIN ? 'https://win-verlag.de/datenschutz/' : isIWW ? 'https://legal.vogel.de/legal-cockpit/iww/datenschutzerklaerung/' : 'https://legal.vogel.de/legal-cockpit/vcg-vogel-communications-group/dse/vcg-datenschutzerklaerung-zentral/'" target="_blank">{{
                    'FORM.DATAPROTECTION_2' |
                    translate }}</a>
                {{ 'FORM.DATAPROTECTION_3' | translate }}
            </p>

            <!-- agree with data policy checkbox -->
            <mat-checkbox (change)="dataCheckBoxChange()" formControlName="dataAgreedFormControl" class="data-checkbox"
                required>
                {{ 'FORM.AGREED' | translate }} *
            </mat-checkbox>
            <mat-error *ngIf="dataPolicyValidation == false">
                {{ 'FORM.REQUIREDDATAAGREEMENT' | translate }}
            </mat-error><br>

            <!-- button 'send' at the bottom of the form -->
            <div class="button-position">
                <button>{{ 'FORM.SEND' | translate }}</button>
            </div>

            <p style="padding-top: 14px;font-size: 14px">* {{ 'FORM.REQUIREDFIELDS' | translate }}</p>
        </div>
    </form>
    <!-- footer -->
    <div [ngStyle]="{'position':(param.requestType === 'zua' && choosenBrand.hasPrint == 1 && choosenBrand.datam == 0) || (param.requestType === 'run' || param.requestType === 'ts' || param.requestType === 'sa' || param.requestType === 'madr' || param.requestType === 'madst' || param.requestType === 'mae'  || (param.requestType === 'ap' && param.topic) || (param.requestType === 'd' && param.topic) || param.requestType === 'eus' || (param.requestType === 'webiww' && param.topic) || param.requestType === 'wlk' || param.requestType === 'sf' || param.requestType === 'eshop' || param.requestType === 'app' || param.requestType === 'tp') ? 'relative' : 'absolute' }"
        class="footer" role="footer">
        <a style="font-weight: 500;" [ngStyle]="{'color': isWIN ? '#51C760' : isIWW ? '#00305e' : '#2289f7' }"
            [attr.href]="isWIN ? 'https://win-verlag.de/impressum/' : isIWW ? 'https://legal.vogel.de/legal-cockpit/iww/zentrale-rechtstexte/impressum/' : 'https://legal.vogel.de/legal-cockpit/vcg-vogel-communications-group/zentrale-rechtstexte/vcg-impressum-2/'"
            target="_blank" rel="noreferrer">{{ 'FOOTER.IMPRINT' | translate }}</a> | <a style="font-weight: 500;"
            [ngStyle]="{'color': isWIN ? '#51C760' : isIWW ? '#00305e' : '#2289f7' }"
            [attr.href]="isWIN ? 'https://win-verlag.de/datenschutz/' : isIWW ? 'https://legal.vogel.de/legal-cockpit/iww/datenschutzerklaerung/' : 'https://legal.vogel.de/legal-cockpit/vcg-vogel-communications-group/dse/vcg-datenschutzerklaerung-zentral/'"
            target="_blank" rel="noreferrer">{{ 'DATAPOLICY.TITLE' | translate }}</a><br>
        Copyright {{ year }} <a style="font-weight: 500;"
            [ngStyle]="{'color': isWIN ? '#51C760' : isIWW ? '#00305e' : '#2289f7' }"
            [attr.href]="isWIN ? 'https://win-verlag.de/' : isIWW ? 'https://www.iww.de/' : 'https://www.vogel.de/'"
            target="_blank" rel="noreferrer">
            {{ isWIN ? 'WIN-Verlag GmbH &amp; Co. KG' : isIWW ? 'IWW Institut für Wissen in der Wirtschaft GmbH' :
            'Vogel Communications Group GmbH &amp; Co. KG' }}
        </a>
    </div>
</div>

<!-- text if ticket successful submitted -->
<div class="content ticket-submitted-text" role="main" *ngIf="ticketDataValidation == true">
    <h1 [ngStyle]="{'color': isWIN ? '#51C760' : isIWW ? '#00305e' : '#2289f7' }">{{ 'FORM.WE_GOT_IT' | translate }}
    </h1>
    <br>
    <h3>{{ 'FORM.SERVICENUMBER' | translate }} <b>{{ ticketId }}</b>.<br><br>
        <p>{{ 'FORM.WE_CHECK_IT_WITHIN_TWO_DAYS' | translate }} </p><br>
        <p>{{ 'FORM.BESTREGARDS' | translate }},</p>
        <p>{{ 'FORM.SUPPORTTEAM' | translate}}</p>
    </h3>
    <!-- footer -->
    <div style="position: absolute;" class="footer" role="footer">
        <a style="font-weight: 500;" [ngStyle]="{'color': isWIN ? '#51C760' : isIWW ? '#00305e' : '#2289f7' }"
            [attr.href]="isWIN ? 'https://win-verlag.de/impressum/' : isIWW ? 'https://legal.vogel.de/legal-cockpit/iww/zentrale-rechtstexte/impressum/' : 'https://legal.vogel.de/legal-cockpit/vcg-vogel-communications-group/zentrale-rechtstexte/vcg-impressum-2/'"
            target="_blank" rel="noreferrer">{{ 'FOOTER.IMPRINT' | translate }}</a> | <a style="font-weight: 500;"
            [ngStyle]="{'color': isWIN ? '#51C760' : isIWW ? '#00305e' : '#2289f7' }"
            [attr.href]="isWIN ? 'https://win-verlag.de/datenschutz/' : isIWW ? 'https://legal.vogel.de/legal-cockpit/iww/datenschutzerklaerung/' : 'https://legal.vogel.de/legal-cockpit/vcg-vogel-communications-group/dse/vcg-datenschutzerklaerung-zentral/'"
            target="_blank" rel="noreferrer">{{ 'DATAPOLICY.TITLE' | translate }}</a><br>
        Copyright {{ year }} <a style="font-weight: 500;"
            [ngStyle]="{'color': isWIN ? '#51C760' : isIWW ? '#00305e' : '#2289f7' }"
            [attr.href]="isWIN ? 'https://win-verlag.de/' : isIWW ? 'https://www.iww.de/' : 'https://www.vogel.de/'"
            target="_blank" rel="noreferrer">
            {{ isWIN ? 'WIN-Verlag GmbH &amp; Co. KG' : isIWW ? 'IWW Institut für Wissen in der Wirtschaft GmbH' :
            'Vogel Communications Group GmbH &amp; Co. KG' }}
        </a>
    </div>
</div>